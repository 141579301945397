<template>
	<HeadingX
		class="c-base-h2"
		:class="{
			'c-base-h2--balance': balance,
		}"
		v-bind="{ tag }"
		:level-offset="levelOffset"
	>
		<template v-if="domProps.textContent">
			{{ domProps.textContent }}
		</template>
		<template v-else>
			<slot></slot>
		</template>
	</HeadingX>
</template>

<script>
import HeadingX from '~/components/shared/HeadingX';

export default {
	name: 'BaseH2',
	components: { HeadingX },

	props: {
		tag: {
			type: String,
			default: undefined,
		},
		levelOffset: {
			type: [Number, String],
			default: 1,
		},
		balance: {
			type: Boolean,
			default: true,
		},
	},

	computed: {
		domProps() {
			const { domProps } = this.$vnode?.data || {};
			return domProps || {};
		},
	},
};
</script>

<style lang="postcss">
:where(.c-base-h2) {
	@apply text-h2 font-light whitespace-pre-wrap;
}
:where(.c-base-h2--balance) {
	text-wrap: balance;
}
</style>
