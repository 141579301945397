<!--
	Last modified: 2022/03/17 15:37:46
-->
<template>
	<Component
		:is="computedTag"
		:role="isRealHeading ? null : 'heading'"
		:aria-level="isRealHeading ? null : level"
	>
		<slot></slot>
	</Component>
</template>

<script>
export default {
	name: 'HeadingX',

	inject: {
		headingLevel: {
			default: 1,
		},
	},

	props: {
		tag: {
			type: String,
			default: undefined,
		},

		ariaLevel: {
			type: [Number, String],
			default: undefined,
		},

		levelOffset: {
			type: [Number, String],
			default: 1,
		},
	},

	computed: {
		level() {
			if (this.ariaLevel || typeof this.ariaLevel === 'number') {
				return Number(this.ariaLevel);
			}
			return this.headingLevel + Number(this.levelOffset);
		},

		computedTag() {
			if (this.tag) {
				return this.tag;
			}

			if (this.level >= 1 && this.level <= 6) {
				return `h${this.level}`;
			}

			return 'div';
		},

		isRealHeading() {
			return ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(
				this.computedTag
			);
		},
	},
};
</script>
